import React from 'react'
import Hr from './hr.js'

export default function Person (){
    return(
        <div id="person" name="person" style={{ margin: "30px", border: "1px solid black" }}>
                <Hr />
                <h1> Person</h1>
                <div className="inline">

            
                </div>
            </div>
    )   
}