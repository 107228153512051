import React from 'react'

export default function Education (props) {
  return (
    <>
      <div className='displayPart'>
        <div className='title'>
          <h3> EDUCATION </h3>
        </div>

        <div className='scrollPart'>
          <div className='jobSquare'>
            <h2> PROFESSIONAL DEVELOPMENT  </h2>
          </div>
          <ul>
            
			
			<li>
              Cyber Security Awareness Training - VA State - FEB 2019 -
              <div className='imageLink'>
                <a
                  href='/images/SecurityCertificate.jpg'
                  target='_blank'
                  rel='noreferrer'
                >
                  View
                </a>
              </div>
              <br />
            </li>
            
			<li>Marklogic Semantics - Oct 2017
			<div className='imageLink'>			
				<a href='/images/marklogic3.pdf' target='_blank' rel='noreferrer'>
                  View
                </a>
			</div>
			</li>
			
			<li>Marklogic 9 - Sep 2017
			<div className='imageLink'>			
				<a href='/images/marklogic3.pdf' target='_blank' rel='noreferrer'>
                  View
                </a>
			</div>
			</li>
			
			
			<li>Marklogic Applications - Jun 2017
			<div className='imageLink'>			
				<a href='/images/marklogic2.pdf' target='_blank' rel='noreferrer'>
                  View
                </a>
			</div>
			</li>
            
			<li>MarkLogic Fundamentals - Apr 2017
				<div className='imageLink'>			
					<a href='/images/marklogic1.pdf' target='_blank' rel='noreferrer'>
					  View
					</a>
				</div>
			</li>
            
			
			<li>
              MarkLogic - Part I - OCT 2016
			 
            </li>
            
			<li>
              Udemy - Web Developer Bootcamp - 2016
              <div className='imageLink'>
                <a href='/images/Udemy.jpg' target='_blank' rel='noreferrer'>
                  View
                </a>
              </div>
			  <br/>
            </li>
			
            <li>
              Code School - Try Git
              <br />
            </li>
			
			
			<li>
              ASP.NET USING C# - MAR 2008
              <div className='imageLink'>
                <a href='/images/a_s_p.jpg' target='_blank' rel='noreferrer'>
                  View
                </a>
              </div>
              <div className='imageLink'>
                <a
                  href='/images/brainbench_a_s_p.jpg'
                  target='_blank'
                  rel='noreferrer'
                >
                  BrainBench ASP
                </a>
              </div>
              <div className='imageLink'>
                <a
                  href='/images/brainbench_c.jpg'
                  target='_blank'
                  rel='noreferrer'>
                  BrainBench C#
                </a>
              </div>
            </li>
            
			
			<li>
              DreamWeaver I & II - MAR 2004 -
              <div className='imageLink'>
                <a
                  href='/images/DreamWeaver.jpg'
                  target='_blank'
                  rel='noreferrer'
                >
                  View
                </a>
              </div>
            </li>
            
			
			<li>
              .NET Certificate(MS course 2373) - FEB 2003
              <div className='imageLink'>
                <a href='/images/MSNet.jpg' target='_blank' rel='noreferrer'>
                  View
                </a>
              </div>
            </li>
            
			
			<li>
              Central Virginia FoxPro User Group - Founder -
              <div className='imageLink'>
                <a href='/images/CVFox.jpg' target='_blank' rel='noreferrer'>
                  View
                </a>
              </div>
              <br />
            </li>
            
            
			<li>
              Virginia Commonwealth University - Geographic Information Systems
              - 1996
            </li>
            
			<li>
              BS Information Systems - Virginia Commonwealth University - School
              of Business
              <br />
              Bachelor of Science(BS) Information Technology <br />
              Grade: 2.8
              <br />
              1992 – 1996
            </li>
			
            
			
			
			<li>Walter Panas High School - 1981 – 1983</li>
			
          </ul>

          <div className='jobSquare'>
            <h2> Industry Knowledge </h2>
          </div>

          <ul>
            <li>SDLC</li>
            <li>Database Design</li>
            <li>Web Applications</li>
            <li>Software Development</li>
            <li>Web Development</li>
            <li>Programming</li>
            <li>Web Design</li>
            <li>Requirements Analysis</li>
            <li>Software Documentation</li>
			<li>GIS/ESRI/ArcView</li>
			<li>Health Level Seven (HL7) Language</li>
          </ul>
        </div>
      </div>
    </>
  )
}
