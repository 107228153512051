import React from 'react'
import $ from 'jquery'

function toggleText(){
	$('.addTextArea').toggle()
}

function replaceScreenName(){
	$('.screenName').text($('#inputScreenName').val()+'Screen')
	$('.upperCaseFirstLetter').text(upperCaseFirstLetter($('#inputScreenName').val())+'Screen')
	
	$('.lowercase').text($('#inputScreenName').val()+'screen')
}

function replaceText(){
	$('#textValue').text($('#textIncludeText').val())
}

function upperCaseFirstLetter(word){
	return word[0].toUpperCase() + word.slice(1);
}

function toggleButton(){
	$('.addButtonArea').toggle()
}

function replaceButtonText(){
	$('#buttonValue').text($('#textIncludeButton').val())
}

export default function Home (props) {
  return (
    <>
	
	
	<div  style={{textAlign:"center"}}>
		<img style={{maxWidth: "100%", height: "auto"}} alt="beach" src="/images/beach.jpg"/>
		<h1> Welcome to my profile page. </h1>
		
		<div onDoubleClick={()=>{ $('#toolsBox').show() }} >
			<img style={{maxWidth: "100%", height: "auto"}} alt="developer" src="/images/developer.gif"/>
		
			<div  id="toolsBox" style={{display:"none", position:"absolute", bottom:"30px", left:"600px", border:"1px solid white", height:"50px", overflowY:"scroll"}}>
				<span>TOOLS</span>
				<br/>
				<button  onClick={ ()=> {$("#htmlCleanerDiv").toggle()}}>HTML Cleaner</button>
				
				<br/>
				<button  onClick={ ()=> {$("#centerdiv").toggle()}}>centerDiv</button>
				
				<br/>
				<button  onClick={ ()=> {$("#classdiv").toggle()}}>Class Constructor</button>

				<br/>
				<button  onClick={ ()=> {$("#templatediv").toggle()}}>React Native Template</button>
				
				<br/>
				<button  onClick={ ()=> {$("#arrayFrequencyCounter").toggle()}}>Array Frequency Counter</button>
				
				<br/>
				<button  onClick={ ()=> {$("#fetchDiv").toggle()}}>Fetch</button>
				
			</div>
		</div>
		
		<div id="centerdiv" className="centerDiv" style={{textAlign:"start",display:"none", backgroundColor:"white"}}>
			.centerDiv&#123;<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;position:&nbsp;absolute;<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;left:&nbsp;50%;<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;top:&nbsp;50%;<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transform:&nbsp;translate(-50%,&nbsp;-50%);<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;border:&nbsp;5px&nbsp;solid&nbsp;#FFFFFF;<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;padding:&nbsp;10px;<br/>
			&#125;<br/>
		</div>
		
		<div id="classdiv" className="centerDiv" style={{textAlign:"start",display:"none", backgroundColor:"white"}}>
		class&nbsp;Cat&nbsp;&#123;<br/>
		&nbsp;&nbsp;constructor(name)&nbsp;&#123;<br/>
		&nbsp;&nbsp;&nbsp;&nbsp;this.name&nbsp;=&nbsp;name<br/>
		&nbsp;&nbsp;&#125;<br/>
		&#125;
		</div>
		
		<div id="arrayFrequencyCounter" className="centerDiv" style={{textAlign:"start",display:"none", backgroundColor:"white"}}>
			const&nbsp;arr&nbsp;=&nbsp;[&quot;all&quot;,&quot;all&quot;,&quot;all&quot;,&quot;all&quot;,2,2,&quot;all&quot;,4];<br/>
			const&nbsp;getFrequency&nbsp;=&nbsp;(array)&nbsp;=&gt;&nbsp;&#123;<br/>
			&nbsp;&nbsp;&nbsp;const&nbsp;map&nbsp;=&nbsp;&#123;&#125;;<br/>
			&nbsp;&nbsp;&nbsp;array.forEach(item&nbsp;=&gt;&nbsp;&#123;<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(map[item])&#123;<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[item]++;<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;else&#123;<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[item]&nbsp;=&nbsp;1;<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
			&nbsp;&nbsp;&nbsp;&#125;);<br/>
			&nbsp;&nbsp;&nbsp;return&nbsp;map;<br/>
			&#125;;<br/>
			console.log(getFrequency(arr));
		</div>
		
		
		<div id="fetchDiv" className="centerDiv" style={{textAlign:"start",display:"none", backgroundColor:"white"}}>
		const&nbsp;App&nbsp;=&nbsp;()&nbsp;=&gt;&nbsp;&#123;<br/>
&nbsp;&nbsp;const&nbsp;[Joke,&nbsp;setJoke]&nbsp;=&nbsp;React.useState(&#39;&#39;)<br/>
&nbsp;&nbsp;const&nbsp;fetchApi&nbsp;=&nbsp;()&nbsp;=&gt;&nbsp;&#123;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;fetch(&#39;https://sv443.net/jokeapi/v2/joke/Programming?type=single&#39;)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.then(res&nbsp;=&gt;&nbsp;res.json())<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.then(data&nbsp;=&gt;&nbsp;setJoke(data.joke))<br/>
&nbsp;&nbsp;&#125;<br/>
&nbsp;&nbsp;return&nbsp;(<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;Joke&nbsp;===&nbsp;&#39;&#39;&nbsp;?&nbsp;&lt;button&nbsp;onClick=&#123;fetchApi&#125;&gt;Hit&nbsp;me&lt;/button&gt;&nbsp;:&nbsp;&lt;p&gt;&#123;Joke&#125;&lt;/p&gt;&#125;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
&nbsp;&nbsp;)<br/>
&#125;
		</div>
		
		
		<div id="templatediv" className="centerDiv templateDiv" style={{textAlign:"start",display:"none", backgroundColor:"white"}}>
			Screen Name:<input id="inputScreenName" type="text" onChange={replaceScreenName}/><br/>
			<input id="inputIncludeText" type="checkbox" onChange={toggleText}/>Include Text
			<input id="textIncludeText" type="text" onChange={replaceText}/>
			<br/>
			<input id="inputIncludeButton" type="checkbox" onChange={toggleButton}/>Include Button
			<input id="textIncludeButton" type="text" onChange={replaceButtonText}/>
			<hr/>
			import <span className="screenName upperCaseFirstLetter">New Screen</span> from &quot;./src/screens/<span className="screenName lowercase">New Screen</span>.js&quot;
			<hr/>
			import&nbsp;*&nbsp;as&nbsp;React&nbsp;from&nbsp;&#39;react&#39;<br/>
			import&nbsp;&#123;&nbsp;View 
			<span className="addTextArea" style={{display:'none'}}>,&nbsp;Text</span>
			<span className="addButtonArea" style={{display:'none'}}>,&nbsp;Button&nbsp;</span>
			&#125;&nbsp;from&nbsp;&#39;react-native&#39;<br/>
			<span style={{display:'none'}}>import&nbsp;&#123;&nbsp;NavigationContainer&nbsp;&#125;&nbsp;from&nbsp;&#39;@react-navigation/native&#39;<br/></span>
			<span style={{display:'none'}}>import&nbsp;&#123;&nbsp;createStackNavigator&nbsp;&#125;&nbsp;from&nbsp;&#39;@react-navigation/stack&#39;<br/></span>
			<br/>
			function&nbsp;<span className="screenName upperCaseFirstLetter">New Screen</span>&nbsp;(
			<span style={{display:'none'}}>&#123;&nbsp;route,&nbsp;navigation&nbsp;&#125;</span>
			)&nbsp;&#123;<br/>
			&nbsp;&nbsp;return&nbsp;(<br/>
			&nbsp;&nbsp;&nbsp;&nbsp;&lt;View&gt;
			<span className="addTextArea" style={{display:'none'}}>
				<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Text&gt;<span id="textValue">New Text</span>&nbsp;&lt;/Text&gt;
			</span>
			<br/>
			<span className="addButtonArea" style={{display:'none'}}>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button title=&#39;<span id='buttonValue'>New Button</span>&#39;/&gt;<br/>
			</span>
			&nbsp;&nbsp;&nbsp;&nbsp;&lt;/View&gt;<br/>
			&nbsp;&nbsp;)<br/>
			&#125;<br/>
			export&nbsp;default&nbsp;<span className="screenName upperCaseFirstLetter">New Screen</span>
		</div>
			
			
		
	</div>
    </>
  )
}
