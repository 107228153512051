import React from 'react';
import $ from 'jquery'

export default function HTMLCleaner(){


function escape(s) {
  var escaped = {
    " ":"&nbsp;",
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
	"{": '&#123;',
	"}": '&#125;',
    '"': '&quot;'
  };
  var retVal =  s.replace(/[&<>'{}" ]/g, function (m) {
    return escaped[m];
  }).replace( /[\r\n]+/gm, "<br/>\r\n" );;
  
  
  return retVal
}




	return(
	
	<>
		<div id="htmlCleanerDiv" style={{display:"none"}} className="centerDiv">
			<textarea cols="75" rows="10" style={{margin:"10px"}} id="transformHTML"></textarea>
			<br/>
			<button style={{margin:"5px"}} onClick={()=> {$('#transformHTML').val(escape($('#transformHTML').val()))}}>Transform HTML</button>
			<button style={{margin:"5px"}} onClick={()=>{$('#transformHTML').select();document.execCommand("copy"); $('#transformHTML').val('');  $('#htmlCleanerDiv').toggle()}}>Copy</button>
		</div>
	</>
	);
}